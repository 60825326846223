@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterSemiBold.ttf");
  font-family: "Inter";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterMedium.ttf");
  font-family: "Inter";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsRegular.ttf");
  font-family: "Poppins";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterRegular.ttf");
  font-family: "Inter";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/LeagueSpartanBold.ttf");
  font-family: "League Spartan";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/InterBold.ttf");
  font-family: "Inter";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OpenSansRomanSemiBold.ttf");
  font-family: "Open Sans";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/OpenSansRegular.ttf");
  font-family: "Open Sans";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsMedium.ttf");
  font-family: "Poppins";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/DMSansMedium.ttf");
  font-family: "DM Sans";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/DMSansRegular.ttf");
  font-family: "DM Sans";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PoppinsSemiBold.ttf");
  font-family: "Poppins";
  font-weight: 600;
}
